import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import CaseStudiesDetailsContent from "../components/CaseStudiesDetails/CaseStudiesDetailsContent"
import RelatedProjects from "../components/CaseStudiesDetails/RelatedProjects"

const CaseDetails = () => {
  const pageTitle = "Case Study - Next Departure"
  const activePageText = "Case Study Next Departure"

  // Main Content Area
  const type =
    "1 React Native App (iOS/Android), "
  const title = "An efficient way to build a subscription app."
  const body1 = "The Challenge - Next Departure's goal was to create both an iOS and Android application using a minimal amount of development time. The most important feature is notification due to the time sensitive nature of mistake deals."
  const body2 = "The Solution - With severely limited budget, we delivered the appliation originally with React Native because it uses a combination of cross platform and native code where needed. Notification was built through Firebase for Android, and Apple's directly with APNS."
  const body3 = "Overall, Next Departure's iOS and Android App was delivered working and on schedule. Next departure currently have active subscriptions and continues to find mistake flights for the savvyest deal seekers."

  const results = "The Result - A working and tested app was delivered within 3 months and within budget. Free maintenance for built components was included."

  // Side bar content area
  const clientName = "Next Departure"
  const clientLink = "https://nextdeparture.ca"
  const clientName2 = ""
  const clientLink2 = ""
  const location = "Toronto, ON, Canada"
  const technologies = "Front-end: React Native"
  const completionDate = ""
  const products = [{ hrefName: "Next Departure iOS", href: "https://apps.apple.com/ca/app/next-departure-cheap-flights/id1388394030" },
                    { hrefName: "Next Departure Android", href: "https://play.google.com/store/apps/details?id=com.NextDeparture&hl=en&gl=US" },
                  ]

  return (
    <Layout pageTitle={pageTitle}>
      <Navbar />
      <PageBanner
        pageTitle={pageTitle}
        homePageText="Home"
        homePageUrl="/"
        activePageText={activePageText}
      />
      <CaseStudiesDetailsContent
        type={type}
        title={title}
        body1={body1}
        body2={body2}
        body3={body3}
        results={results}
        clientName={clientName}
        clientLink={clientLink}
        clientName2={clientName2}
        clientLink2={clientLink2}
        location={location}
        technologies={technologies}
        completionDate={completionDate}
        products={products}
      />
      {/* <RelatedProjects /> */}
      <Footer />
    </Layout>
  )
}

export default CaseDetails
